
import { defineComponent, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'

export default defineComponent({
  components: {
    InfoCard,
    InfoCardRow,
    TmFormLine,
    TmEmoji,
  },
  props: {
    initialName: {
      type: String,
    },
  },
  setup(props) {
    const name = ref(props.initialName)

    return {
      name,
    }
  },
})
