export const defaultDates = [
  'All time',
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'Last 90 days',
]

export const datesWithoutAllTime = [
  'Last 90 days',
  'Last 30 days',
  'Last 7 days',
  'Yesterday',
  'Today',
]
