import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sharing_select = _resolveComponent("sharing-select")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_info_card = _resolveComponent("info-card")!

  return (_openBlock(), _createBlock(_component_info_card, {
    title: "Sharing",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        label: "View is visible to",
        class: "max-w364px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_sharing_select)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}