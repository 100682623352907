
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownFilter from '@/components/shared/dropdowns/TmDropdownFilter.vue'
import DropdownFilterStatuses from '@/components/shared/filters/dropdown/DropdownFilterStatuses.vue'
import DropdownFilterStatusesSingle from '@/components/shared/filters/dropdown/DropdownFilterStatusesSingle.vue'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import DropdownFilterDate from '@/components/shared/filters/dropdown/DropdownFilterDate.vue'
import DropdownFilterPriority from '@/components/shared/filters/dropdown/DropdownFilterPriority.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import DropdownFilterAutocomplete from '@/components/shared/filters/dropdown/DropdownFilterAutocomplete.vue'
import type { FilterItems } from '@/definitions/shared/dropdowns/types'

export default defineComponent({
  components: {
    DropdownFilterPriority,
    TmDropdownFilter,
    DropdownFilterStatuses,
    DropdownFilterStatusesSingle,
    DropdownFilterCheckbox,
    DropdownFilterDate,
    DropdownFilterAssignee,
    DropdownFilterAutocomplete,
  },
  props: {
    modelValue: {
      type: Array as PropType<FilterItems[]>,
    },
    filterItems: {
      type: Array as PropType<FilterItems[]>,
    },
  },
  emits: ['update:modelValue'],
})
