
import { defineComponent, ref, watch } from 'vue'
import FilterFooter from '@/components/shared/filters/FilterFooter.vue'
import { defaultDates } from '@/definitions/_general/_data/dates'

export default defineComponent({
  name: 'FilterDate',
  components: { FilterFooter },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
      default: () => defaultDates,
    },
    hideStateBtns: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'onApply', 'onCancel'],
  setup(props, context) {
    const mutableValue = ref<string>(props.modelValue)

    const onInput = (val: any) => {
      mutableValue.value = val
      if (props.hideStateBtns) {
        context.emit('update:modelValue', mutableValue.value)
      }
    }
    const onApply = () => {
      context.emit('update:modelValue', mutableValue.value)
      context.emit('onApply')
    }
    const onCancel = () => {
      mutableValue.value = props.modelValue
      context.emit('onCancel')
    }

    const fromDate = ref(new Date())
    const toDate = ref(new Date())

    watch(
      () => props.modelValue,
      () => {
        mutableValue.value = props.modelValue
      }
    )

    return {
      mutableValue,
      onInput,
      onApply,
      onCancel,
      fromDate,
      toDate,
    }
  },
})
