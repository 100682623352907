
import { defineComponent, reactive, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    InfoCard,
    TmFormLine,
  },
  setup() {
    const state = reactive({
      group: 'No grouping',
      groupOrder: 'asc',
      sort: 'Priority',
      sortOrder: 'desc',
    })

    const groupList = ref([
      'No grouping',
      'Priority',
      'Subject',
      'Requester',
      'Status',
    ])

    const sortList = ref([
      'Priority',
      'Subject',
      'Requester',
      'Status',
    ])

    return {
      state,
      groupList,
      sortList,
    }
  },
})
