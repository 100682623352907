
import { defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TicketsFilteredViewsName from '@/components/pages/tickets/settings/filteredViews/detailed/TicketsFilteredViewsName.vue'
import TicketsFilteredViewsFilters from '@/components/pages/tickets/settings/filteredViews/detailed/TicketsFilteredViewsFilters.vue'
import TicketsFilteredViewsColumns from '@/components/pages/tickets/settings/filteredViews/detailed/TicketsFilteredViewsColumns.vue'
import TicketsFilteredViewsSharing from '@/components/pages/tickets/settings/filteredViews/detailed/TicketsFilteredViewsSharing.vue'
import TicketsFilteredViewsGrouping from '@/components/pages/tickets/settings/filteredViews/detailed/TicketsFilteredViewsGrouping.vue'
import { useRoute } from 'vue-router'
import InfoCards from '@/components/shared/templates/InfoCards.vue'

export default defineComponent({
  components: {
    InfoCards,
    TicketsFilteredViewsGrouping,
    TicketsFilteredViewsSharing,
    TicketsFilteredViewsColumns,
    TicketsFilteredViewsFilters,
    TicketsFilteredViewsName,
    PageContent,
    DetailsHero,
  },
  setup() {
    const route = useRoute()
    const title = route.params.new ? 'New filtered view' : 'Edit filtered view'
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Filtered views', url: { name: 'base.tickets.settings.filteredViews' } },
      { label: title },
    ]
    const name = ref(route.params.new ? '' : 'Open email requests')

    return {
      name,
      breadcrumbs,
      title,
    }
  },
})
