
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import FilterPanelButton from '@/components/shared/filters/FilterPanelButton.vue'
import FilterDate from '@/components/shared/filters/FilterDate.vue'

export default defineComponent({
  name: 'DropdownFilterDate',
  components: { FilterDate, FilterButton, TmDropdown, FilterPanelButton },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    class: {
      type: String,
    },
    name: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    panelView: {
      type: Boolean,
    },
    btnSize: {
      type: String as PropType<'default' | 'small' | 'large' | 'xLarge'>,
      default: 'large',
    },
  },
  setup(props) {
    return {
      wrapperClass: props.class,
    }
  },
})
