
import { defineComponent, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import FiltersList from '@/components/shared/templates/filteredView/FiltersList.vue'
import { PriorityFilter, TicketsStatusesFilter, AssigneeFilter, RequesterFilter, LastUpdatedFilter, CreatedFilter } from '@/definitions/shared/dropdowns/data'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    FiltersList,
  },
  setup() {
    const { openModal } = useModals()
    const filterItems = ref([
      TicketsStatusesFilter,
      PriorityFilter,
      AssigneeFilter,
      RequesterFilter,
      LastUpdatedFilter,
      CreatedFilter,
    ])

    const filters = ref(filterItems.value)

    return {
      filters,
      openModal,
      filterItems,
    }
  },
})
