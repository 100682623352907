import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8827d220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-prepend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_info_card_row = _resolveComponent("info-card-row")!
  const _component_info_card = _resolveComponent("info-card")!

  return (_openBlock(), _createBlock(_component_info_card, {
    title: "Name",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_info_card_row, null, {
        text: _withCtx(() => [
          _createVNode(_component_tm_form_line, {
            label: "View name",
            class: "max-w320px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_field, {
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                placeholder: "Enter name"
              }, {
                prepend: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_tm_emoji, { name: "rocket" })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}