import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_date = _resolveComponent("filter-date")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    class: _normalizeClass(_ctx.wrapperClass),
    "filter-dropdown": "",
    fit: ""
  }, {
    menu: _withCtx(({ close }) => [
      _createVNode(_component_filter_date, _mergeProps(_ctx.$attrs, {
        class: "dropdown-wrap",
        "model-value": _ctx.modelValue,
        onOnApply: close,
        onOnCancel: close
      }), null, 16, ["model-value", "onOnApply", "onOnCancel"])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.panelView ? 'filter-panel-button' : 'filter-button'), {
        class: "mr-2",
        removable: _ctx.removable,
        value: _ctx.modelValue || 'All',
        name: _ctx.name,
        "btn-size": _ctx.btnSize
      }, null, 8, ["removable", "value", "name", "btn-size"]))
    ]),
    _: 1
  }, 8, ["class"]))
}