
import { defineComponent, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmDropdownFilter from '@/components/shared/dropdowns/TmDropdownFilter.vue'
import TmDropdownFilterPanel from '@/components/shared/dropdowns/TmDropdownFilterPanel.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmDropdownFilterPanel,
    InfoCard,
    TmDropdownFilter,
  },
  setup() {
    const columns = ref([
      {
        uid: 1,
        name: 'Priority',
      },
      {
        uid: 2,
        name: 'Subject',
      },
    ])

    const columnsList = [
      {
        uid: 1,
        name: 'Priority',
      },
      {
        uid: 2,
        name: 'Subject',
      },
      {
        uid: 3,
        name: 'Requester',
      },
      {
        uid: 4,
        name: 'Created',
      },
      {
        uid: 5,
        name: 'Assignee',
      },
      {
        uid: 6,
        name: 'Status',
      },
      {
        uid: 7,
        name: 'Last updated',
      },
      {
        uid: 8,
        name: 'Satisfaction',
      },
      {
        uid: 9,
        name: 'Organization',
      },
      {
        uid: 10,
        name: 'Inbox',
      },
      {
        uid: 11,
        name: 'CC',
      },
      {
        uid: 12,
        name: 'Date closed',
      },
      {
        uid: 13,
        name: 'Custom field 1',
      },
    ]

    return {
      columns,
      columnsList,
    }
  },
})
