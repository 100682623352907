import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_filter_panel = _resolveComponent("tm-dropdown-filter-panel")!
  const _component_tm_dropdown_filter = _resolveComponent("tm-dropdown-filter")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_info_card = _resolveComponent("info-card")!

  return (_openBlock(), _createBlock(_component_info_card, {
    title: "Columns",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_form_line, { label: "Columns visible by default" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_dropdown_filter, {
            modelValue: _ctx.columns,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columns) = $event)),
            "dropdown-label": "Add column",
            "all-selected-text": "All columns added",
            class: "max-w364px",
            options: _ctx.columnsList,
            "btn-under-options": ""
          }, {
            "dropdown-item": _withCtx(({ opt }) => [
              _createTextVNode(_toDisplayString(opt.name), 1)
            ]),
            "selected-item": _withCtx(({ opt }) => [
              _createVNode(_component_tm_dropdown_filter_panel, {
                title: opt.name,
                draggable: ""
              }, null, 8, ["title"])
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}