import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_tickets_filtered_views_name = _resolveComponent("tickets-filtered-views-name")!
  const _component_tickets_filtered_views_filters = _resolveComponent("tickets-filtered-views-filters")!
  const _component_tickets_filtered_views_columns = _resolveComponent("tickets-filtered-views-columns")!
  const _component_tickets_filtered_views_grouping = _resolveComponent("tickets-filtered-views-grouping")!
  const _component_tickets_filtered_views_sharing = _resolveComponent("tickets-filtered-views-sharing")!
  const _component_info_cards = _resolveComponent("info-cards")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: _ctx.title,
        icon: "tmi-filter-list",
        class: "mb-4"
      }, null, 8, ["title"]),
      _createVNode(_component_info_cards, null, {
        default: _withCtx(() => [
          _createVNode(_component_tickets_filtered_views_name, { "initial-name": _ctx.name }, null, 8, ["initial-name"]),
          _createVNode(_component_tickets_filtered_views_filters),
          _createVNode(_component_tickets_filtered_views_columns),
          _createVNode(_component_tickets_filtered_views_grouping),
          _createVNode(_component_tickets_filtered_views_sharing)
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}