
import { defineComponent } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import SharingSelect from '@/components/shared/templates/SharingSelect.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
    SharingSelect,
    InfoCard,
  },
})
